@import url('https://fonts.cdnfonts.com/css/red-hat-display');

@keyframes fadeImg {
  0%, 24% {
    background-image: url(https://beautiful-essentials-cloud-front-bucket.s3.amazonaws.com/images/phone_info_2.png)
  }

  33.33%, 57.66% {
    background-image: url(https://beautiful-essentials-cloud-front-bucket.s3.amazonaws.com/images/phone_info_1.png)
  }

  66.66%, 90.99% {
    background-image: url(https://beautiful-essentials-cloud-front-bucket.s3.amazonaws.com/images/phone_info_3.png)
  }

  99.99%, 100% {
    background-image: url(https://beautiful-essentials-cloud-front-bucket.s3.amazonaws.com/images/phone_info_2.png)
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent
}

html {
  background-color: rgba(0, 0, 0, 1)
}

body, html {
  font-family: "Raleway"
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none
}

.bold, .header-1, body .footer .footer-links a:hover {
  text-decoration: underline
  /* font-weight: 700 !important */
}

.bold, .header-1, body .footer .footer-links .manage-cookies:hover {
  text-decoration: underline;
  cursor: pointer;
}

.header-1 {
  font-size: 30px;
  line-height: 33px;
  margin: 0;
  font-feature-settings: 'pnum'on, 'lnum'on;
  text-transform: none
}

.header-1.bold {
  font-weight: 700 !important
}

.header-2 {
  font-size: 20px;
  font-weight: 400;
  margin: 0;
  font-feature-settings: 'pnum'on, 'lnum'on;
  line-height: 25px
}

.regular {
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  font-feature-settings: 'pnum'on, 'lnum'on;
  color: #333;
  line-height: 23px
}

.remark {
  font-size: 13px;
  line-height: 14px;
  margin: 0;
  text-transform: none;
  letter-spacing: normal;
  font-feature-settings: 'pnum'on, 'lnum'on
}

.button {
  border-radius: 24px;
  font-size: 15px;
  text-align: center;
  border: none;
  outline: none;
  display: inline-block;
  background-color: transparent;
  color: rgba(17,17,17,1.0);
  box-sizing: border-box;
  padding: 8px;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  height: 44px;
  line-height: 32px;
  max-width: 156px;
}

.button:disabled {
  cursor: auto;
}

.button:not(:disabled):hover {
  cursor: pointer;
}

.button.raised {
  font-weight: 600;
  color: white;
}

.button.raised.white {
  background-color: white;
  color: rgba(221, 3, 105, 1)!important;
  border: 1px solid rgba(221, 3, 105, 1);
  border-radius: 10px!important;
}

.button.raised.titles {
  color: white!important;
  background-color: rgba(10,42,80,1.0)!important;
}

.button.raised.pink {
  color: white!important;
  background-color: rgba(221, 3, 105, 1)!important;
  border-radius: 10px!important;
}

.white {
  color: #fff !important
}

.pink {
  color: rgba(221, 3, 105, 1) !important
}

.flex-spacer {
  flex: 1 0 auto
}

.hidden {
  display: none!important;
}

.layout-row {
  display: flex;
  flex-direction: row
}

.layout-column {
  display: flex;
  flex-direction: column
}

.center-center {
  display: flex;
  align-items: center;
  justify-content: center
}

.center-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.left-center {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.content {
  background-size: 100%auto;
  background-position: 50% calc(100% - 80px);
  background-repeat: no-repeat;
  padding: 0 0 40px
}

.content.stretched {
  box-sizing: border-box;
  max-width: 100vw;
  padding: 0;
  width: 100vw
}

body {
  min-height: 100vh;
  width: 100vw;
  /* max-width: 1440px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* padding: 74px 74px 0; */
  margin: 0 auto
}

body #root {
  width: 100%;
}

body .pdf-link {
  margin: 0 0 20px;
  font-weight: 700;
  color: #000;
}

body .option-radio {
  height: 20px;
  width: 20px;
  box-sizing: border-box;
  padding: 2px;
  margin-right: 8px;
  flex-shrink: 0;
}

body .option-radio:not([disabled]):hover {
  cursor: pointer;
}

body .Footer, body .Header,
body .footer, body .header {
  position: relative;
  width: 100%;
  min-width: 100%;
  height: 100%
}

body .header img {
  width: 100%
}

body .header .header-logo {
  max-width: 1120px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: absolute;
  left: calc(50% - 560px);
  flex-shrink: 0;
}

body .header .header-logo .header-logo-img {
  width: auto;
  height: 100%;
  /* height: 60px; */
  position: absolute;
  left: 0;
}

body .footer .footer-links .remark, body .footer .footer-links .line-separator {
  margin-right: 15px
}

body .cookies-consent {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  overflow-x: auto;
  overflow-y: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center
}

body .cookies-consent .cookies-content {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: auto;
  text-align: left;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 4px 0 4px 0 rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: row;
  padding: 8px 164px;
  box-sizing: border-box;
  justify-content: space-between!important;
  overflow: auto;
}

body .cookies-consent .cookies-content .first-page, .second-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between!important;
}

body .cookies-consent .cookies-content svg {
  position: absolute;
  padding: 8px;
  right: 12px;
  top: 12px;
}

body .cookies-consent .cookies-content svg:hover {
  cursor: pointer;
}

body .cookies-consent .cookies-content .header-2 {
  text-align: left;
}

body .cookies-consent .cookies-content .explanation {
  max-width: 628px;
}

body .cookies-consent .cookies-content .explanation a {
  text-decoration: underline;
}

body .cookies-consent .cookies-content .button {
  width: 204px;
  height: 42px;
  border-radius: 24px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

body .cookies-consent .cookies-content .reject {
  margin: 0 60px 0 10px;
}

body .cookies-consent .cookies-content .button:hover {
  cursor: pointer;
}

body .cookies-consent .cookies-content .cookies-settings {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

body .cookies-consent .cookies-content .cookies-settings .setting-option {
  display: flex;
  flex-direction: row;
  margin-right: 50px;
  margin-bottom: 0;
}

body .cookies-consent .cookies-content .cookies-settings .setting-option:hover {
  cursor: pointer;
}

body .cookies-consent .cookies-content .cookies-settings .setting-option:last-child {
  margin-right: 0;
}

body .cookies-consent .cookies-content .cookies-settings .setting-option.disabled:hover {
  cursor: not-allowed;
}

body .cookies-consent .cookies-content .cookies-settings .setting-option.disabled .regular {
  color: rgba(102,102,102,1.0);
}

body .cookies-consent .cookies-content .cookies-settings .setting-option .regular {
  color: rgba(153,153,153,1.0);
}

body .cookies-consent .cookies-content .cookies-settings .setting-option .regular.selected {
  color: rgba(102,102,102,1.0);
}

body .cookies-consent .cookies-content a.regular {
  text-decoration: underline;
  font-size: 16px!important;
}

body .cookies-consent .cookies-content .navigation {
  flex-shrink: 0;
  background-color: rgba(10,42,80,1.0);
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 136px;
  max-width: 100%;
  padding: 0 24px;
  border-radius: 20px;
  flex-direction: column;
}

body .cookies-consent .cookies-content .navigation:hover {
  cursor: pointer;
}

body .cookies-consent .cookies-content .personalize-settings, .cookie-policy {
  text-decoration: underline;
  margin-right: 50px;
}

body .cookies-consent .cookies-content .personalize-settings:hover, .cookie-policy:hover {
  cursor: pointer;
}

body .homepage {
  background: rgba(0, 0, 0, 1)
}

body .homepage .headline {
  text-align: left;
  margin-bottom: 15%;
  position: relative;
  width: 100%;
  /* min-height: 850px */
}

body .homepage .headline .titles {
  z-index: 2;
  width: 100%;
  margin: 80px auto 0;
  max-width: 1120px;
}

body .homepage .headline .title {
  margin: 0 0 20px
}

body .homepage .headline .title.display {
  font-family: "Red Hat Display", sans-serif;
  color: #fff;
  letter-spacing: 0;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: 'clig'off, 'liga'off;
  max-width: 540px;
  font-size: 50px;
  font-weight: 700;
  line-height: 58px
}

body .homepage .headline .title.hollow {
  margin: 0;
  color: transparent;
  font-weight: 700;
  letter-spacing: 10px;
  line-height: 100px;
  -webkit-text-stroke: 4px #fff
}

body .homepage .headline .title.sub-title {
  padding-right: 66%
}

body .homepage .right-background {
  z-index: 1;
  position: absolute;
  top: 0px;
  right: 0;
  max-height: 1000px;
  width: 100%
}

body .homepage .carrousel-container {
  margin-bottom: 100px;
  width: 100%;
}

body .homepage .carrousel-container .title {
  margin: 0 0 40px 29.5%;
  text-decoration: none;
  font-weight: 700;
}

body .homepage .carrousel-container .carrousel {
  height: 264px;
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  white-space: nowrap;
}

body .homepage .banners-contanier {
  margin: 0 auto 88px;
  width: auto;
  max-width: 1120px;
}

body .homepage .banners-contanier .title {
  align-self: flex-start;
  text-decoration: none !important;
  cursor: auto;
  font-weight: 700;
  margin-bottom: 36px;
}

body .homepage .banners-contanier .banner {
  width: auto;
}

body .homepage .banners-contanier .banner:not(:last-of-type) {
  margin-bottom: 10px;
}

body .homepage .banners-contanier .banner .app-banner {
  width: 364px;
  height: 178px;
  flex: 0 0 auto;
}

body .homepage .banners-contanier .banner .app-banner:not(:last-of-type) {
  margin-right: 10px;
}

body .homepage .banners-contanier .banner .app-banner img {
  width: 100%;
}

body .homepage .categories-contaner {
  flex-wrap: wrap;
  width: auto;
  margin-bottom: 20px;
}

body .homepage .categories-contaner .category {
  width: 160px;
  height: 40px;
  border-radius: 12px;
  background-color: #1F1F1F;
  font-size: 16px;
  text-decoration: none;
  font-weight: 600;
  margin-bottom: 30px;
}

body .homepage .categories-contaner .category:hover {
  cursor: pointer;
}

body .homepage .categories-contaner .category:not(:last-of-type) {
  margin-right: 24px;
}

body .homepage .categories-contaner .category.selected {
  color: #ff3895!important;
  background-color: #fff;
}

body .homepage .all-icons {
  margin: 0 auto;
  width: auto;
  max-width: 1120px;
}

body .homepage .all-icons .icons-title {
  margin: 0 0 40px;
  align-self: flex-start;
  text-decoration: none!important;
  cursor: auto;
  font-weight: 700;
}

body .homepage .app-icons {
  margin: 0 0 40px;
  flex-wrap: wrap;
  width: auto;
}

/* body .homepage .app-icons:last-of-type {
  margin: 0 0 120px
} */

body .homepage Link, .App .homepage a {
  width: 100%;
  text-decoration: none
}

body .homepage .app-icon {
  max-width: 328px
  /* min-width: 345px */
}

body .homepage .app-icon:not(:last-of-type) {
  margin-right: 42px
}

body .homepage .app-icon img {
  margin-right: 16px;
  width: 80px;
  height: 80px
}

body .homepage .app-icon p {
  margin: 0;
  text-align: left;
  max-width: 150px
}

body .homepage .app-icon .app-link {
  width: 90px;
  height: 30px;
  background-color: #1F1F1F;
  border-radius: 12px;
  color: #FF3895;
  font-weight: 700;
}

body .homepage .app-icon .line-separator {
  width: 345px;
  border: 1px solid #333;
  margin: 30px 0 25px;
}

body .homepage .google-play img {
  margin-top: 10px;
  width: 150px
}

body .footer .footer-links {
  flex-wrap: wrap;
}

body .footer .footer-links a, body .footer .footer-links p {
  margin-bottom: 16px
}

body .footer .footer-links .remark {
  text-decoration: none;
}

body .privacy {
  width: 100%;
  justify-self: center;
  margin: 0 auto;
  max-width: 1440px
}

body .privacy .header {
  max-width: 1440px
}

body .privacy-terms {
  padding: 40px;
  color: #000;
  width: 100%;
  box-sizing: border-box;
  background-color: #fff
}

body .privacy-terms .visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}

body .privacy-terms .toc-list, .toc-list ol {
list-style-type: none;
}

body .privacy-terms .toc-list {
padding: 0;
}

body .privacy-terms .toc-list ol {
padding-inline-start: 2ch;
}

body .privacy-terms .toc-list > li > a {
font-weight: bold;
margin-block-start: 1em;
}

body .privacy-terms .toc-list li > a {
  text-decoration: none;
  display: grid;
  grid-template-columns: auto max-content;
  align-items: end;
}

body .privacy-terms .toc-list li > a > .title {
  position: relative;
  overflow: hidden;
}

body .privacy-terms .toc-list li > a .leaders::after {
  position: absolute;
  padding-inline-start: .25ch;
  content: " . . . . . . . . . . . . . . . . . . . "
      ". . . . . . . . . . . . . . . . . . . . . . . "
      ". . . . . . . . . . . . . . . . . . . . . . . "
      ". . . . . . . . . . . . . . . . . . . . . . . "
      ". . . . . . . . . . . . . . . . . . . . . . . "
      ". . . . . . . . . . . . . . . . . . . . . . . "
      ". . . . . . . . . . . . . . . . . . . . . . . ";
  text-align: right;
}

body .privacy-terms .toc-list li > a > .page {
  min-width: 2ch;
  font-variant-numeric: tabular-nums;
  text-align: right;
}

body .privacy .google-play {
  padding: 80px 40px
}

body li {
  padding-left: 20px
}

body .privacy-terms .header-2.bold {
  margin-bottom: 4px
}

body .privacy-terms .regular {
  margin-bottom: 18px
}

body .privacy-terms a.regular {
  font-size: 16px!important;
}

body .privacy-terms .manage-cookies {
  display: inline-block;
  text-decoration: underline;
}

body .privacy-terms .manage-cookies:hover {
  cursor: pointer;
}

body .privacy-terms .terms-list {
  padding: 0;
}

body .privacy-terms .terms-list-table {
  padding-left: 8px;
  box-sizing: border-box;
}

body .privacy-terms .terms-list-table li {
  padding-bottom: 4px;
  box-sizing: border-box;
}

body .privacy-terms .table-wrapper {
  max-width: 100%;
  overflow-x: auto;
  margin-bottom: 16px;
}

body .privacy-terms .parties {
  border-collapse: collapse;
  width: 100%;
}

body .privacy-terms .parties td, tr {
  height: 64px;
  box-sizing: border-box;
  border-collapse: collapse;
  padding: 8px 16px;
  border: 1px solid #d8dcdf;
}

body .privacy-terms .parties thread td {
  font-weight: 700;
}

body .homepage {
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  min-width: 100%;
  padding-bottom: 68px;
  background-color: rgba(0, 0, 0, 1);
  text-align: left
}

body .homepage .left-side {
  margin: 0 117px 68px 40px;
  height: 100%;
  min-height: 680px;
  min-width: 600px;
  align-items: flex-start;
  justify-content: flex-start;
}

body .homepage .left-side .phone {
  position: relative
}

body .homepage .left-side .phone #details-frame {
  top: 0;
  left: 0
}

body .homepage .left-side .phone #details-info {
  top: 23px;
  left: 9px
}

body .homepage .left-side .phone #continue-frame {
  top: 70px;
  left: 134px
}

body .homepage .left-side .phone #continue-info {
  top: 93px;
  left: 143px
}

body .homepage .left-side .phone #qr-code-frame {
  top: 134px;
  left: 268px
}

body .homepage .left-side .phone #qr-code-info {
  top: 157px;
  left: 277px
}

body .homepage .left-side .phone .phone-frame {
  width: 330px;
}

body .homepage .left-side .phone img {
  width: 314px;
  position: absolute;
  z-index: 2
}

body .homepage .right-side {
  align-items: flex-start;
  justify-content: center;
  /* margin-top: 181px;
  margin-right: 40px;
  max-width: 450px */
}

body .homepage .right-side .qr-scan {
  width: 120px;
  margin-bottom: 40px
}

body .homepage .right-side .header-2 {
  color: rgba(202, 202, 202, 1);
  margin-bottom: 25px
}

body .homepage .right-side .regular {
  margin-bottom: 40px
}

body .cookies-consent .cookies-content .explanation {
  font-size: 16px;
}

body .cookies-consent .cookies-content .explanation a, Link {
  font-size: 16px;
  color: #000;
}

body .enter-email-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  background-color: rgba(0,0,0,0.7);
  transition: opacity 0.7s ease-in-out;
  opacity: 0;
  z-index: 99999;
}

body .enter-email-background.visible {
  transition: opacity 0.7s ease-in-out;
  opacity: 1;
}

body .enter-email-background .enter-email {
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  border-radius: 10px;
  position: relative;
  max-width: 512px;
  max-height: 600px;
  background-color: white;
  text-align: center;
  overflow: auto;
}

body .enter-email-background .enter-email .close-popup {
  width: 26px;
  height: 26px;
  position: absolute;
  top: 15px;
  left: 15px;
}

body .enter-email-background .enter-email .banner img {
  width: 100%;
  border-radius: 10px 10px 0 0;
}

body .enter-email-background .enter-email .close-popup:hover {
  cursor: pointer;
}

body .enter-email-background .enter-email .register-email-form {
  width: 100%;
  box-sizing: border-box;
  padding: 27px;
}

body .enter-email-background .enter-email .register-email-form .header-1, .after-register-popup .header-1 {
  margin-bottom: 10px;
  text-decoration: none;
}

body .enter-email-background .enter-email .register-email-form .consent-check {
  align-items: flex-start;
  margin-bottom: 36px;
}

body .enter-email-background .enter-email .register-email-form .consent-check .setting-option {
  display: flex;
  text-align: left;
  justify-content: center;
  align-items: flex-start;
}

body .enter-email-background .enter-email .register-email-form .consent-check .option-radio {
  margin-right: 10px!important;
  margin-top: 5px;
}

body .enter-email-background .enter-email .register-email-form .consent-check .setting-option p, a.regular {
  font-size: 12px!important;
}

body .enter-email-background .enter-email .register-email-form input:not([type=submit]) {
  max-width: 350px;
  width: 100%;
  height: 44px;
  border: 1px solid rgba(202, 202, 202, 1);
  background: WHITE;
  margin: 32px 0;
  border-radius: 5px;
  padding-left: 15px;
  box-sizing: border-box;
}

body .enter-email-background .enter-email .register-email-form input:not([type=submit])::placeholder {
  color: rgba(102, 102, 102, 1);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

body .enter-email-background .enter-email .register-email-form input[type=submit] {
  width: 156px;
  height: 44px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 0;
}

body .enter-email-background .enter-email .register-email-form input[type=submit]:disabled {
  background-color: rgba(255, 186, 218, 1)!important;
}

body .enter-email-background .enter-email .register-email-form .error-info {
  margin-top: 32px;
  color: red;
}

body .enter-email-background .enter-email .register-email-form .error-info:hover {
  cursor: default;
}

body .enter-email-background .enter-email .after-register-popup {
  min-height: 146px;
  height: 146px;
  width: 100%;
}


@media screen and (max-width:1224px) and (max-device-width:1224px) {
  .header-1 {
    font-size: 28px
  }

  .header-2 {
    font-size: 18px
  }

  .regular {
    font-size: 15px
  }

  .remark {
    font-size: 12px
  }

  body {
    background-color: rgba(0, 0, 0, 1)
  }

  .lg-only {
    display: none !important
  }

  body .header {
    background-color: rgba(0, 0, 0, 1);
    box-sizing: border-box;
    /* padding: 60px 16px 34px */
  }
  
  /* body .header img {
    width: 328px
  } */

  body .homepage .google-play img {
    width: 160px
  }

  body .footer {
    display: flex;
    flex-direction: column;
    padding: 24px 24px 70px;
    text-align: center;
    background-color: #1c1826
  }

  body .footer .ccpa-opt-out:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  body .footer .footer-links a, body .footer .footer-links p {
    margin-bottom: 12px
  }

  body .privacy-terms .parties.four-columns td {
    width: 25%;
  }

  body .enter-email-background {
    max-width: 960px;
    padding: 0 20px;
  }

  body .enter-email-background .enter-email .register-email-form {
    height: 600px;
  }

  body .enter-email-background .enter-email .after-register-popup {
    max-width: 100%;
    width: 100%;
  }

  body .cookies-consent {
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
  }

  body .cookies-consent .cookies-content {
    padding: 16px 20px 20px;
    align-items: flex-start!important;
    border-radius: 10px 10px 0 0;
  }

  body .cookies-consent .first-page, .second-page {
    text-align: center;
    align-items: center!important;
    flex-direction: column!important;
  }

  body .cookies-consent .cookies-content .header-2 {
    align-self: flex-start;
  }
  
  body .cookies-consent .cookies-content .explanation {
    max-width: 664px;
    font-size: 12px!important;
    text-align: center;
  }
  
  body .cookies-consent .cookies-content .explanation a, Link {
    font-size: 12px!important;
  }

  body .cookies-consent .cookies-content .button {
    width: 152px;
    align-self: center;
    text-align: center;
  }

  body .cookies-consent .cookies-content .first-buttons {
    width: 100%;
    flex-direction: column;
  }

  body .cookies-consent .cookies-content .second-buttons {
    width: 100%;
    flex-direction: column;
  }

  body .cookies-consent .cookies-content .cookies-settings {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  body .cookies-consent .cookies-content .cookies-settings .setting-option {
    margin-right: 0;
    margin-bottom: 22px;
  }

  body .cookies-consent .cookies-content .cookies-settings .setting-option:last-child {
    margin-bottom: 0;
  }

  body .cookies-consent .cookies-content .cookies-settings .setting-option .regular {
    font-size: 12px;
  }

  body .cookies-consent .cookies-content .personalize-settings, .cookie-policy {
    font-weight: 700;
    font-size: 12px;
    align-self: flex-start;
    margin: 14px 0 20px;
    margin-right: 0;
    align-self: center;
  }

  body .cookies-consent .cookies-content .reject {
    font-weight: 700;
    font-size: 12px;
    align-self: flex-start;
    margin: 14px 0 20px;
    margin-right: 0;
    align-self: center;
    color: #333!important;
    border: none;
    text-decoration: underline;
  }

  body .homepage {
    padding: 0 24px;
  }

  body .homepage .headline {
    text-align: center;
    margin-bottom: 70px;
    position: relative;
    width: 100%;
  }

  body .homepage .headline .titles {
    z-index: 2;
    margin-top: 0;
    margin-left: 0
  }
  
  body .homepage .headline .title {
    margin: 0 0 20px
  }

  body .homepage .headline .sub-title {
    padding-right: 0!important;
  }

  body .homepage .headline .title.display {
    font-family: "Red Hat Display", sans-serif;
    color: #fff;
    letter-spacing: 0;
    font-variant-numeric: lining-nums proportional-nums;
    font-feature-settings: 'clig'off, 'liga'off;
    max-width: none;
    font-size: 28px;
    font-weight: 700;
    line-height: 33px
  }

  body .homepage .carrousel-container {
    margin-bottom: 70px;
    width: 100%;
  }
  
  body .homepage .carrousel-container .title {
    margin: 0 0 20px 0;
    text-decoration: none;
    align-self: center;
  }
  
  body .homepage .carrousel-container .carrousel {
    height: 164px;
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
  }
  
  body .homepage .carrousel-container .carrousel .app-banner {
    width: 300px;
    height: 154px;
    flex: 0 0 auto;
  }
  
  body .homepage .carrousel-container .carrousel .app-banner:not(:last-of-type) {
    margin-right: 10px;
  }
  
  body .homepage .carrousel-container .carrousel .app-banner img {
    width: 100%;
  }
  
  body .homepage .categories-contaner {
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 20px;
  }
  
  body .homepage .categories-contaner .category {
    width: 90px;
    height: 40px;
    border-radius: 12px;
    background-color: #1F1F1F;
    font-size: 15px;
    text-decoration: none;
    font-weight: 600;
    margin: 0 10px 30px!important;
  }
  
  body .homepage .categories-contaner .category:hover {
    cursor: pointer;
  }
  
  /* body .homepage .categories-contaner .category:not(:last-of-type) {
    margin-right: 20px;
  } */
  
  body .homepage .categories-contaner .category.selected {
    color: #ff3895!important;
    background-color: #fff;
  }

  body .homepage .all-icons {
    margin: 0 auto;
    width: auto;
    max-width: 100%;
  }
  
  body .homepage .all-icons .icons-title {
    margin: 0 0 20px;
    align-self: center;
    /* align-self: flex-start; */
    text-decoration: none!important;
    cursor: auto;
    font-weight: 700;
  }
  
  body .homepage .app-icons {
    margin: 0 0 40px;
    flex-wrap: wrap;
    width: 100%;
  }
  
  /* body .homepage .app-icons:last-of-type {
    margin: 0 0 120px
  } */
  
  body .homepage Link, .App .homepage a {
    width: 100%;
    text-decoration: none
  }
  
  body .homepage .app-icon {
    min-width: 312px
  }
  
  body .homepage .app-icon:not(:last-of-type) {
    margin-right: 0
  }
  
  body .homepage .app-icon img {
    margin-right: 10px;
    width: 70px;
    height: 70px
  }
  
  body .homepage .app-icon p {
    margin: 0;
    text-align: left;
    font-size: 15px!important;
    line-height: 22px!important;
    max-width: 150px
  }
  
  body .homepage .app-icon .app-link {
    width: 90px;
    height: 30px;
    background-color: #1F1F1F;
    border-radius: 12px;
    color: #FF3895;
  }
  
  body .homepage .app-icon .line-separator {
    width: 312px;
    border: 1px solid #333;
    margin: 30px 0 25px;
  }
}

@media screen and (max-width:1224px) and (max-device-width:1224px) and (orientation: landscape){
  .header-1 {
    font-size: 28px
  }

  .header-2 {
    font-size: 18px
  }

  .regular {
    font-size: 15px
  }

  .remark {
    font-size: 12px
  }

  body {
    animation: none;
    background-color: rgba(0, 0, 0, 1)
  }

  .sm-only {
    display: flex !important
  }

  .lg-only {
    display: none !important
  }

  body .header {
    background-color: rgba(0, 0, 0, 1);
    box-sizing: border-box;
    padding: 0
    /* padding: 60px 16px 34px */
  }
  
  /* body .header img {
    width: 328px
  } */

  body .footer {
    display: flex;
    flex-direction: column;
    padding: 24px 24px 70px;
    text-align: center;
    background-color: #1c1826
  }

  body .footer .ccpa-opt-out:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  body .footer .footer-links a, body .footer .footer-links p {
    margin-bottom: 12px
  }

  body .privacy-terms .parties.four-columns td {
    width: 25%;
  }

  body .enter-email-background {
    max-width: 960px;
    padding: 0 20px;
  }

  body .enter-email-background .enter-email .register-email-form {
    height: 600px;
  }

  body .enter-email-background .enter-email .after-register-popup {
    max-width: 100%;
    width: 100%;
  }

  body .cookies-consent {
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
  }

  body .cookies-consent .cookies-content {
    padding: 16px 20px 20px;
    align-items: flex-start!important;
    border-radius: 10px 10px 0 0;
  }

  body .cookies-consent .first-page, .second-page {
    text-align: center;
    align-items: center!important;
    flex-direction: column!important;
  }

  body .cookies-consent .cookies-content .header-2 {
    align-self: flex-start;
  }
  
  body .cookies-consent .cookies-content .explanation {
    max-width: 664px;
    font-size: 12px!important;
    text-align: center;
  }
  
  body .cookies-consent .cookies-content .explanation a, Link {
    font-size: 12px!important;
  }

  body .cookies-consent .cookies-content .button {
    width: 152px;
    align-self: center;
    text-align: center;
  }

  body .cookies-consent .cookies-content .first-buttons {
    width: 100%;
    flex-direction: column;
  }

  body .cookies-consent .cookies-content .second-buttons {
    width: 100%;
    flex-direction: column;
  }

  body .cookies-consent .cookies-content .cookies-settings {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  body .cookies-consent .cookies-content .cookies-settings .setting-option {
    margin-right: 0;
    margin-bottom: 22px;
  }

  body .cookies-consent .cookies-content .cookies-settings .setting-option:last-child {
    margin-bottom: 0;
  }

  body .cookies-consent .cookies-content .cookies-settings .setting-option .regular {
    font-size: 12px;
  }

  body .cookies-consent .cookies-content .personalize-settings, .cookie-policy {
    font-weight: 400;
    font-size: 12px;
    align-self: flex-start;
    margin-bottom: 20px;
    margin-right: 0;
    align-self: center;
  }

  body .homepage {
    padding: 0 24px;
  }

  body .homepage .headline {
    text-align: center;
    margin-bottom: 70px;
    position: relative;
    width: 100%;
  }

  body .homepage .headline .titles {
    z-index: 2;
    margin-top: 0;
    margin-left: 0
  }
  
  body .homepage .headline .title {
    margin: 0 0 20px
  }

  body .homepage .headline .sub-title {
    padding-right: 0!important;
  }

  body .homepage .headline .title.display {
    font-family: "Red Hat Display", sans-serif;
    color: #fff;
    letter-spacing: 0;
    font-variant-numeric: lining-nums proportional-nums;
    font-feature-settings: 'clig'off, 'liga'off;
    max-width: none;
    font-size: 28px;
    font-weight: 700;
    width: 100%;
    line-height: 33px
  }

  body .homepage .carrousel-container {
    margin-bottom: 70px;
    width: 100%;
  }
  
  body .homepage .carrousel-container .title {
    margin: 0 0 20px 0;
    text-decoration: none;
    align-self: center;
  }
  
  body .homepage .carrousel-container .carrousel {
    height: 164px;
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
  }
  
  body .homepage .carrousel-container .carrousel .app-banner {
    width: 300px;
    height: 154px;
    flex: 0 0 auto;
  }
  
  body .homepage .carrousel-container .carrousel .app-banner:not(:last-of-type) {
    margin-right: 10px;
  }
  
  body .homepage .carrousel-container .carrousel .app-banner img {
    width: 100%;
  }
  
  body .homepage .categories-contaner {
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 20px;
  }
  
  body .homepage .categories-contaner .category {
    width: 90px;
    height: 40px;
    border-radius: 12px;
    background-color: #1F1F1F;
    font-size: 15px;
    text-decoration: none;
    font-weight: 600;
    margin: 0 10px 30px!important;
  }
  
  body .homepage .categories-contaner .category:hover {
    cursor: pointer;
  }
  
  /* body .homepage .categories-contaner .category:not(:last-of-type) {
    margin-right: 20px;
  } */
  
  body .homepage .categories-contaner .category.selected {
    color: #ff3895!important;
    background-color: #fff;
  }

  body .homepage .all-icons {
    margin: 0 auto;
    width: auto;
    max-width: 100%;
  }
  
  body .homepage .all-icons .icons-title {
    margin: 0 0 20px;
    align-self: center;
    /* align-self: flex-start; */
    text-decoration: none!important;
    cursor: auto;
    font-weight: 700;
  }
  
  body .homepage .app-icons {
    margin: 0 0 40px;
    flex-wrap: wrap;
    width: 100%;
  }
  
  /* body .homepage .app-icons:last-of-type {
    margin: 0 0 120px
  } */
  
  body .homepage Link, .App .homepage a {
    width: 100%;
    text-decoration: none
  }
  
  body .homepage .app-icon {
    min-width: 312px
  }
  
  body .homepage .app-icon:not(:last-of-type) {
    margin-right: 0
  }
  
  body .homepage .app-icon img {
    margin-right: 10px;
    width: 70px;
    height: 70px
  }
  
  body .homepage .app-icon p {
    margin: 0;
    text-align: left;
    font-size: 15px!important;
    line-height: 22px!important;
    max-width: 150px
  }
  
  body .homepage .app-icon .app-link {
    width: 90px;
    height: 30px;
    background-color: #1F1F1F;
    border-radius: 12px;
    color: #FF3895;
  }
  
  body .homepage .app-icon .line-separator {
    width: 312px;
    border: 1px solid #333;
    margin: 30px 0 25px;
  }
}

@media only screen and (min-width:1224px) and (min-device-width:1224px) {
  .sm-only {
    display: none !important
  }

  body .header {
    background-color: rgba(0, 0, 0, 1);
    padding: 0
    /* padding: 0 0 45px */
  }

  body .footer {
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    text-align: center;
    /* background-color: #1c1826 */
  }

  body .footer .ccpa-opt-out:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  /* body .footer .copy-right {
    margin-right: 15px
  } */
  
  body .footer .line-separator {
    margin-right: 15px
  }
}